import React from 'react'
import { ExploreArrow } from '../../assets/icons/icon';
import DCLogo from '../../assets/images/DCDialLogo.svg';
import EpifonyLogo from '../../assets/images/EpifonyLogo.svg';
import AvnovoLogo from '../../assets/images/AvLogo.svg';
import Frame1 from '../../assets/images/Frame12.png';
import Frame2 from '../../assets/images/Frame13.png';
import { useWindowSize } from '../Hooks/useWindowSize';

const HeroSection = ({ sectionRefs, handleClickScroll }) => {
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  return (
    <div
      className="relative w-full h-[calc(100vh-66px)] max-h-[1080px] bg-PrimaryT max-w-[1920px] m-auto max_md:px-10 max_sm:px-5"
      id="ScrollTop"
      ref={sectionRefs}
    >
      <div className="absolute top-[-46px] left-0">
        {/* <Frame1 /> */}
        <img src={Frame1} alt="F1" />
      </div>
      <div className="absolute bottom-0 right-0 z-0">
        {/* <Frame1 /> */}
        <img src={Frame2} alt="F1" />
      </div>
      <div className="flex flex-col items-center max-w-[1040px] justify-center m-auto h-full">
        <h1 className="text-[60px] max_md:text-[50px] max_sm:text-[30px] font-OutfitFont font-semibold text-white text-center">
          Redefining Business <br /> Efficiency, One Solution at a Time
        </h1>
        <p className="text-text2Xl max_md:text-textMedium max_sm:text-textSmall font-OutfitFont font-normal text-white mt-10 text-center max_sm:mt-[30px]">
          Revolutionary cloud solutions for the agile enterprise.
        </p>

        <div
          className="mt-[60px] max_sm:mt-10 w-[220px] max_sm:w-[184px] rounded-[60px] border-[1px] border-white flex gap-2 items-center px-6 py-[11px] FLEX_CENTER cursor-pointer z-10"
          onClick={() => handleClickScroll('ProductId')}
        >
          <p className="text-textNormal max_sm:text-textExtraSmall font-OutfitFont text-white font-normal">
            Explore Products
          </p>
          <ExploreArrow
            style={{
              width: isMobileView ? 20 : 24,
              height: isMobileView ? 20 : 24,
            }}
          />
        </div>

        <div className="z-10 mt-[60px] max_sm:mt-10 flex gap-[30px] items-center max_sm:flex-wrap max_sm:justify-center">
          <div
            className="max_sm:max-w-[115px] cursor-pointer min-w-[165px] max_md:min-w-0"
            onClick={() => handleClickScroll('EpifonyId')}
          >
            <img src={EpifonyLogo} alt="Epifony" />
          </div>
          <div className="w-[2px] h-[26px] bg-StrokeActive max_sm:hidden"></div>
          <div
            className="max_sm:max-w-[90px] cursor-pointer min-w-[127px] max_md:min-w-0"
            onClick={() => handleClickScroll('AvnovoId')}
          >
            <img src={AvnovoLogo} alt="avnovo" />
          </div>
          <div className="w-[2px] h-[26px] bg-StrokeActive max_sm:hidden"></div>
          <div
            className="max_sm:max-w-[92px] cursor-pointer min-w-[122px] max_md:min-w-0"
            onClick={() => handleClickScroll('DCDialId')}
          >
            <img src={DCLogo} alt="DcDial" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection